<template>
  <b-card>
    <b-tabs
      nav-wrapper-class="border-b"
      pills>
      <b-tab
        active
        title-link-class="rounded-pill"
        title="Ticket Detail"
        @click="refreshTabDetail()">
        <!-- <TicketDetail
          v-if="isShowComponent"
          :customer-detail="customerProfile"
        /> -->
        <TicketAdd
          v-if="isShowComponent"
          :ticket-id="ticket.id"
          :initial-ticket.sync="ticket"
          :ticket-value.sync="ticket"
          :all-ref="allRef"
          :initial-resolve-date.sync="resolveDate"
          :initial-due-date.sync="dueDate"
          @add-ticket="addTicket($event)"
          @save-and-closed="addTicketAndClosed($event)"
          @selected-category="getResolveAndDue($event)"
          @selectd-user="selectUser($event)"
        />
      </b-tab>
      <b-tab
        title-link-class="rounded-pill"
        title="รายละเอียดฝ่ายกฏหมาย">
        <b-tabs
          card>
          <b-tab
            no-body
            title="ข้อมูลคดี">
            <CaseInformation
              :ticket-id="ticket.id"
              :initial-case-information.sync="caseInformation"
              :case-information-value.sync="caseInformation" />
          </b-tab>
          <b-tab
            no-body
            title="คำฟ้อง">
            <Indictment
              :ticket-id="ticket.id"
              :initial-indictment.sync="indictment"
              :indictment-value.sync="indictment" />
          </b-tab>
          <b-tab
            no-body
            title="คำพิพากษา/คำชี้ขาด">
            <Verdict
              :ticket-id="ticket.id"
              :initial-verdict.sync="verdict"
              :verdict-value.sync="verdict" />
          </b-tab>
          <b-tab
            no-body
            title="การนัดหมาย">
            <Appointment
              :ticket-id="ticket.id"
              :initial-appointment.sync="appointment"
              :appointment-value.sync="appointment" />
          </b-tab>
          <b-tab
            no-body
            title="การชดใช้เงิน">
            <Reimbursement
              :ticket-id="ticket.id"
              :compound-interest.sync="reimbursement.compoundInterest"
              :initial-reimbursement.sync="reimbursement"
              :reimbursement-value.sync="reimbursement" />
          </b-tab>
          <b-tab
            no-body
            title="ความเคลื่อนไหวของคดี">
            <MovementCase
              :ticket-id="ticket.id"
              :initial-movement-case.sync="movementCase"
              :movement-case-value.sync="movementCase" />
          </b-tab>
        </b-tabs>
        <!-- <TicketProfile
          :customer-profile="customerProfile"
          :customer-ani="item.ani"
          :customer-items.sync="customerProfileItems"
          @search-customer="getCustomerProfileByAni($event)"
          @search-customer-id="getCustomerProfileById($event)"
          @search-name-customer="getCustomerProfileByName($event)"
          @select-customer="selectCustomer($event)"
          @reset-form="resetFormTicketProfile()"
        /> -->
      </b-tab>
      <b-tab
        title-link-class="rounded-pill"
        title="รายละเอียดการชดใช้เงิน">
        <PaymentDetail
          :ticket-id="ticket.id"
          :initial-payment-detail.sync="paymentDetail"
          :payment-detail-value.sync="paymentDetail" />
      </b-tab>
      <b-tab
        title-link-class="rounded-pill"
        title="ประวัติเคส">
        <CaseHistory />
      </b-tab>
      <b-tab
        title-link-class="rounded-pill"
        title="ข้อมูลการประเมินความพึงพอใจ">
        <SatisfactionAssessmentVue />
      </b-tab>
      <b-tab
        title-link-class="rounded-pill"
        title="ข้อมูล รพ.">
        <HospitalInformation />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import CustomerProvider from '@/resources/CustomerProvider'
import TicketTypeProvider from '@/resources/TicketType'
import CategoriesProvider from '@/resources/CategoriesProvider'
import PlantProvider from '@/resources/PlantProvider'
import TicketProvider from '@/resources/TicketProvider'
import ContactChannleProvider from '@/resources/ContactChannelProvider'
import CallCategoryProvider from '@/resources/CallCategoryProvider'
import ContactRelationProvider from '@/resources/ContactReletionProvider'
import OicListProvider from '@/resources/OicListProvider'
import SpacialCaseProvider from '@/resources/SpacialCaseProvider'
import SolutionTypeProvider from '@/resources/SolutionTypeProvider'
import UploadService from '@/resources/UploadProvider'
// import TicketDetail from './TicketDetail.vue'
import CaseInformation from './CaseInformation.vue'
import Indictment from './Indictment.vue'
import MovementCase from './MovementCase.vue'
import Verdict from './Verdict.vue'
import Appointment from './Appointment.vue'
import Reimbursement from './Reimbursement.vue'
import PaymentDetail from './PaymentDetail.vue'
import CaseHistory from './CaseHistory.vue'
import SatisfactionAssessmentVue from './SatisfactionAssessment.vue'
import HospitalInformation from './HospitalInformation.vue'
import TicketAdd from './TicketAdd.vue'
// import TicketProfile from './TicketProfile.vue'

const CustomerSerivce = new CustomerProvider()
const ContactChannelService = new ContactChannleProvider()
const CallCategoryServie = new CallCategoryProvider()
const CategoriesService = new CategoriesProvider()
const TicketService = new TicketProvider()
const TicketTypeService = new TicketTypeProvider()
const PlantService = new PlantProvider()
const ContactRelationService = new ContactRelationProvider()
const OicListService = new OicListProvider()
const SpacialCaseService = new SpacialCaseProvider()
const SolutionTypeService = new SolutionTypeProvider()

export default {
  components: {
    // TicketDetail,
    CaseInformation,
    Indictment,
    MovementCase,
    Verdict,
    Appointment,
    Reimbursement,
    PaymentDetail,
    CaseHistory,
    SatisfactionAssessmentVue,
    HospitalInformation,
    TicketAdd
    // TicketProfile
  },
  data () {
    return {
      isShowComponent: true,
      customerProfileItems: [],
      initialCustomerProfile: {
        id: '',
        customerId: '',
        name: '',
        customerTypeId: '',
        customerGroupId: null,
        sapSoldToCode: '',
        sapShipToCode: '',
        refCode1: '',
        refCode2: '',
        refCode3: '',
        companyAddress: '',
        companySubDistrict: '',
        companyDistrict: '',
        companyProvince: '',
        companyZipCode: '',
        companyOfficePhone: '',
        companyMobilePhone: '',
        companyEmail: '',
        companyGroupId: null,
        companyDepartmentId: null,
        companyCenterId: null,
        remark1: '',
        remark2: '',
        remark3: '',
        queueAgent: 1
      },
      customerProfile: {
        id: '',
        customerId: '',
        name: '',
        customerTypeId: '',
        customerGroupId: null,
        sapSoldToCode: '',
        sapShipToCode: '',
        refCode1: '',
        refCode2: '',
        refCode3: '',
        companyAddress: '',
        companySubDistrict: '',
        companyDistrict: '',
        companyProvince: '',
        companyZipCode: '',
        companyOfficePhone: '',
        companyMobilePhone: '',
        companyEmail: '',
        companyGroupId: null,
        companyDepartmentId: null,
        companyCenterId: null,
        remark1: '',
        remark2: '',
        remark3: '',
        queueAgent: 1
      },
      ticket: {
        customerName: 'chawanwit',
        contactName: '',
        customerLevel: '1',
        contactRelationId: '',
        policyNumber: '1234',
        groupPolicyNumber: '',
        contactNumberPhone: '',
        contactChannelId: null,
        contactTypeId: '',
        email: '',
        oicListId: '',
        caseStatus: '',
        statusSla: '',
        caseType: null,
        caseSubject: null,
        subSubject: null,
        subSubject2: null,
        subSubject3: null,
        title: '',
        specialCaseId: '',
        dueDate: null,
        levelOfProblem: '',
        severity: '',
        solutionTypeId: '',
        controlNumber: '',
        controlCode: '',
        accused: '',
        problemDetail: '',
        solutionDetail: '',
        noteRemark: '',
        voiceFileLink: '',
        remark: '',
        code: '',
        ticketTypeId: null,
        callCategoryId: null,
        priority: '',
        contactPhoneNo: '',
        categoryLv1: null,
        categoryLv2: null,
        categoryLv3: null,
        categoryLv4: null,
        categoryLv5: null,
        categoryLv6: null,
        plantId: '',
        qty: '',
        time: '',
        subject: '',
        ticketCause: '',
        ticketResolve: '',
        ticketDetail: '',
        progressBy: {},
        resolveDate: null,
        remark1: '',
        remark2: '',
        remark3: '',
        createdAt: ''
      },
      caseInformation: {
        code: '',
        receiptDate: '',
        arbitrationCourtId: '',
        blackCase: '',
        redCase: '',
        plaintiff: [
          {
            name: '',
            relationship: '',
            lawyer: ''
          }
        ],
        defendant: [
          {
            name: '',
            relationship: '',
            lawyer: ''
          }
        ]
      },
      indictment: {
        filedDate: '',
        money: '',
        finalPetition: ''
      },
      verdict: {
        arbitrationCourtJudgmentDate: '',
        arbitrationCourtCaseOutcome: '',
        arbitrationCourtJudgmentSummary: '',
        civilCourtJudgmentDate: '',
        civilCourtCaseOutcome: '',
        civilCourtJudgmentSummary: '',
        appealCourtJudgmentDate: '',
        appealCourtCaseOutcome: '',
        appealCourtJudgmentSummary: '',
        supremeCourtJudgmentDate: '',
        supremeCourtCaseOutcome: '',
        supremeCourtJudgmentSummary: '',
        supremeCourtDateOfCaseClosing: '',
        supremeCourtDateOfCompletionOfTheCase: ''
      },
      appointment: {
        date: '',
        time: '',
        details: ''
      },
      reimbursement: {
        principle: null,
        interestRate: null,
        sinceDate: '',
        upToDate: '',
        amountDayInterestCharged: null,
        totalInterest: null,
        compoundInterest: null,
        fees: null,
        totalPayment: null,
        paymentDate: ''
      },
      movementCase: {
        record: '',
        note: ''
      },
      paymentDetail: {
        totalComplaintAmount: null,
        reimbursementAmountConditions: null,
        reimbursementAmountOutsideConditions: null,
        rejectAmount: null,
        normalPremium: null,
        cancelRefund: null
      },
      allRef: {
        ticketType: [],
        contactChannel: [],
        callCategory: [],
        categories: [],
        plant: [],
        contactRelation: [],
        oicList: [],
        specialCase: [],
        solutionType: [],
        policyNumber: [
          { id: '1234', name: '1234' },
          { id: '5678', name: '5678' }
        ],
        contactType: [
          { id: 'test1', name: 'test1' },
          { id: 'test2', name: 'test2' }
        ],
        levelOfProblem: [
          { id: 'Hot', name: 'Hot' },
          { id: 'Warm', name: 'Warm' },
          { id: 'Cool', name: 'Cool' }
        ],
        severity: [
          { id: 'Hot', name: 'Hot' },
          { id: 'Warm', name: 'Warm' },
          { id: 'Cool', name: 'Cool' }
        ]
      },
      progressBy: {},
      resolveDate: '',
      dueDate: ''
    }
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      profile: 'User/user'
    }),
    ticketId () {
      return this.$route.params.id
    },
    iframeTabs () {
      return [
        {
          title: 'Last Invoice',
          src: 'https://csc-report.boonrawd.co.th/LastInvoice?custCode=1025686',
          width: '100%',
          height: '100%'
        },
        {
          title: 'Last Invoice Agent',
          src: 'https://csc-report.boonrawd.co.th/LastInvoiceSml?custCode=25R9005&agentCode=4039000',
          width: '100%',
          height: '100%'
        },
        {
          title: 'Cumulative Sale',
          src: 'https://csc-report.boonrawd.co.th/CumulativeSales?custCode=1025686',
          width: '100%',
          height: '100%'
        },
        {
          title: 'Cumulative Sale (sml)',
          src: ' https://csc-report.boonrawd.co.th/CumulativeSalesSml?custCode=21S0341&agentCode=4039000',
          width: '100%',
          height: '100%'
        }
      ]
    }
  },
  async created () {
    await this.getTicketById()
    await this.getAllOptions()
    this.progressBy = {
      id: this.profile.id,
      username: this.profile.username,
      firstName: this.profile.firstName,
      lastName: this.profile.lastName,
      userGroupId: this.profile.userGroupId,
      userTeamId: this.profile.userTeamId,
      role: this.profile.role
    }
  },
  methods: {
    ...mapActions({
      deleteTicket: 'ticket/DELETE_TICKET'
      // overwriteTicket: 'ticket/OVERWRITE_TICKET'
    }),
    async getTicketById () {
      try {
        const { data } = await TicketService.findOne(this.ticketId)
        this.ticket = { ...data }

        this.resolveDate = this.ticket.resolveDate
        this.dueDate = this.ticket.dueDate
        if (this.ticket.attachFiles) {
          this.ticket.attachFiles = JSON.parse(this.ticket.attachFiles)
        }
        this.filterParentCategory()
        this.$emit('return-ticket', data)
      } catch (error) {
        console.error(error)
      }
    },
    // async getTicketById () {
    //   try {
    //     const { data } = await TicketService.findOne(this.ticketId)
    //     console.log(data)
    //     const ticket = { ...data }
    //     this.overwriteTicket({ index: this.tabIndex, payload: ticket })
    //     this.ticket.attachFiles = JSON.parse(this.ticket.attachFiles)
    //   } catch (error) {
    //     console.error(error)
    //   }
    // },
    async addTicketAndClosed (val) {
      try {
        const attachFiles = await this.uploadFile(val.files)

        if (attachFiles && attachFiles.length > 0) {
          val.attachFiles.push(...attachFiles)
        }
        const payload = {
          ...val,
          progressBy: this.progressBy,
          attachFiles: JSON.stringify(val.attachFiles),
          customerProfileId: this.customerProfile.id || null
        }

        delete payload.code
        delete payload.id
        delete payload.createdAt
        delete payload.createdBy
        delete payload.updatedAt
        delete payload.updatedBy
        delete payload.status
        delete payload.ani
        delete payload.files
        payload.caseStatus = 'closed'

        await TicketService.updateGroup(val.id, payload)
        // this.deleteTicket(this.tabIndex)
        // this.overwriteTicket({ index: this.tabIndex, payload: data })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Case Add Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Case has been added'
          }
        })
        this.$router.push('/case')
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Case Add Fail ${error}`,
            icon: 'EditIcon',
            variant: 'danger',
            text: '👋 Case has been failed'
          }
        })
      }
    },
    async getCustomerProfileByAni (ani) {
      try {
        const { data } = await CustomerSerivce.findByAni(ani)
        if (data) {
          this.setIntialData(data)
          this.ticket.customerName = data.name
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getCustomerProfileById (id) {
      try {
        const { data } = await CustomerSerivce.findOne(id)
        if (data) {
          this.setIntialData(data)
          this.ticket.customerName = data.name
          this.ticket.customerName = data.name
          this.ticket.contactName = data.name
          this.ticket.contactPhoneNo = data.ani || data.companyMobilePhone || data.companyOfficePhone
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getCustomerProfileByName (name) {
      try {
        const { data } = await CustomerSerivce.paginate(1, 10, { searchCustomerName: name })
        if (data) {
          this.customerProfileItems = data
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getAllOptions () {
      try {
        this.isShowComponent = false
        const ticketType = await TicketTypeService.findAllType()
        const contactChannel = await ContactChannelService.findAllChannel()
        const callCategory = await CallCategoryServie.findAllCallCategory()
        const categories = await CategoriesService.findAll()
        const plant = await PlantService.findAllPlant()
        const contactRelation = await ContactRelationService.findAll()
        const oicList = await OicListService.findAll()
        const specialCase = await SpacialCaseService.findAll()
        const solutionType = await SolutionTypeService.findAll()
        this.allRef.ticketType = ticketType.data
        this.allRef.contactChannel = contactChannel.data
        this.allRef.callCategory = callCategory.data
        this.allRef.categories = categories.data
        this.allRef.contactRelation = contactRelation.data
        this.allRef.oicList = oicList.data
        this.allRef.specialCase = specialCase.data
        this.allRef.solutionType = solutionType.data
        this.allRef.plant = plant.data.map((item) => ({ ...item, code: `${item.code} - ${item.name}` }))
      } catch (error) {
        console.error(error)
      } finally {
        this.isShowComponent = true
      }
    },
    async addTicket (val) {
      try {
        const attachFiles = await this.uploadFile(val.files)

        if (attachFiles && attachFiles.length > 0) {
          val.attachFiles.push(...attachFiles)
        }
        const payload = {
          ...val,
          progressBy: this.progressBy,
          attachFiles: JSON.stringify(val.attachFiles),
          customerProfileId: this.customerProfile.id || null
        }

        delete payload.code
        delete payload.id
        delete payload.createdAt
        delete payload.createdBy
        delete payload.updatedAt
        delete payload.updatedBy
        delete payload.status
        delete payload.ani
        delete payload.files

        await TicketService.updateGroup(val.id, payload)
        // this.deleteTicket(this.tabIndex)
        // this.overwriteTicket({ index: this.tabIndex, payload: data })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Case Add Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Case has been added'
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Case Add Fail ${error}`,
            icon: 'EditIcon',
            variant: 'danger',
            text: '👋 Case has been failed'
          }
        })
      }
    },
    async getResolveAndDue (val) {
      try {
        const { data } = await CategoriesService.findOne(val)
        if (data) {
          this.ticket.priority = data.priority
          const calculateDate = dayjs()
            .add(data.resolvedDay, 'd')
            .add(data.resolvedHour, 'h')
            .add(data.resolvedMin, 'm')
            .format('DD MMMM BBBB HH:mm:ss')
          this.resolveDate = calculateDate
          this.dueDate = calculateDate
        }
      } catch (error) {
        console.error(error)
        this.resolveDate = ''
        this.dueDate = ''
      }
    },
    async uploadFile (files) {
      try {
        if (files && files.length > 0 && typeof files === 'object') {
          const arr = []
          for (const item of files) {
            const formData = new FormData()
            formData.append('file', item.file)
            // eslint-disable-next-line no-await-in-loop
            const { data: { data } } = await UploadService.upload(formData)
            arr.push({ name: item.name, url: data.publicUrl })
          }
          return arr
        }
        return []
      } catch (error) {
        console.error(error)
        return []
      }
    },
    setIntialData (val) {
      this.customerProfile = { ...val }
    },
    selectCustomer (val) {
      if (val && val.id) {
        this.customerProfile = val
        this.customerProfile.id = val.id
        this.setIntialData(val)
        this.ticket.customerName = val.name
        this.ticket.contactName = val.name
        this.ticket.contactPhoneNo = val.ani || val.companyMobilePhone || val.companyOfficePhone
      } else {
        this.resetFormTicketProfile()
      }
    },
    selectUser (val) {
      this.progressBy = val
    },
    resetFormTicketProfile () {
      this.customerProfile = { ...this.initialCustomerProfiles }
    },
    returnTicket (e) {
      this.item = e
    },
    refreshTabDetail () {
      this.isShowComponent = !this.isShowComponent
      this.$nextTick(() => {
        this.isShowComponent = !this.isShowComponent
      })
    },
    resetForm (ref) {
      if (ref && ref.resetForm()) ref.resetForm()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
